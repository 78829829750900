"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Trailer.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgTrailer = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs(
        "g",
        {
          stroke: "currentColor",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          clipPath: "url(#trailer_svg__a)",
          children: [
            /* @__PURE__ */ jsx("path", { d: "M8.85 23.05a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" }),
            /* @__PURE__ */ jsx("path", { d: "M5.5 20H1.9c-.22 0-.4-.189-.4-.422v-7.911c0-.923.72-1.667 1.6-1.667h20.8c.88 0 1.6.744 1.6 1.667v7.91c0 .234-.18.423-.4.423H12.3" }),
            /* @__PURE__ */ jsx(
              "path",
              {
                strokeLinecap: "round",
                d: "M24.5 20h5.97s.03-.01.03-.03V18M2.25 13h23"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "trailer_svg__a", children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        }
      ) }) })
    ]
  }
));
var Trailer_default = SvgTrailer;

export {
  Trailer_default
};
