"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Packages.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgPackages = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs(
        "g",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          clipPath: "url(#packages_svg__a)",
          children: [
            /* @__PURE__ */ jsx("path", { d: "M20.403 15.79a.742.742 0 0 1 .097.367v8.97c0 .13-.033.257-.097.368a.717.717 0 0 1-.264.267l-7.792 4.501a.694.694 0 0 1-.694 0L3.86 25.762a.718.718 0 0 1-.264-.267.743.743 0 0 1-.097-.367v-8.97c0-.13.033-.257.097-.368" }),
            /* @__PURE__ */ jsx("path", { d: "M12.097 20.638a.742.742 0 0 1-.097-.367V11.3c0-.13.033-.257.097-.368a.717.717 0 0 1 .264-.267l7.792-4.501a.694.694 0 0 1 .694 0l7.792 4.501c.11.063.2.156.264.267A.742.742 0 0 1 29 11.3v8.97c0 .13-.033.257-.097.368a.717.717 0 0 1-.264.267l-7.792 4.501a.694.694 0 0 1-.694 0" }),
            /* @__PURE__ */ jsx("path", { d: "M20.5 15.413v-8.97a.742.742 0 0 0-.097-.367.717.717 0 0 0-.264-.267l-7.792-4.502a.694.694 0 0 0-.694 0L3.86 5.81a.718.718 0 0 0-.264.267.743.743 0 0 0-.097.367v8.97c0 .13.033.256.097.368a.718.718 0 0 0 .264.266l7.792 4.502a.694.694 0 0 0 .694 0l7.792-4.502c.11-.063.2-.155.264-.266a.742.742 0 0 0 .097-.368Z" }),
            /* @__PURE__ */ jsx("path", { d: "m20.5 15.786-8.415 4.856L3.5 15.785M29 10.93l-8.415 4.856M20.5 6.072l-8.415 4.856L3.5 6.071M12 20.643v9.714M20.5 15.785V25.5M12 10.928v9.714" })
          ]
        }
      ),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "packages_svg__a", children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        }
      ) }) })
    ]
  }
));
var Packages_default = SvgPackages;

export {
  Packages_default
};
