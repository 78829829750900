"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Tv.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgTv = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeWidth: 1.5,
          d: "M16 24.5v3"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeWidth: 1.5,
          d: "M9.5 28.5 16 27l6.5 1.5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M28.856 7H3.144C2.512 7 2 7.45 2 8.003v14.994C2 23.55 2.512 24 3.144 24h25.712c.632 0 1.144-.45 1.144-1.003V8.003C30 7.45 29.488 7 28.856 7Z"
        }
      )
    ]
  }
));
var Tv_default = SvgTv;

export {
  Tv_default
};
