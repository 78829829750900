"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Drill.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgDrill = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs(
        "g",
        {
          stroke: "currentColor",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          clipPath: "url(#drill_svg__a)",
          children: [
            /* @__PURE__ */ jsx("path", { d: "m7.8 11.5-1.24 8.97c-.04.19-.13.37-.27.5l-1.21 1.24c-.38.39-.59.91-.59 1.46v4.29c0 .57.45 1.03 1 1.03h9.01c.55 0 1-.46 1-1.03v-3.87c0-.78-.43-1.49-1.11-1.84l-2.27-1.17c-.37-.19-.59-.61-.55-1.03l.92-8.55" }),
            /* @__PURE__ */ jsx(
              "path",
              {
                strokeLinecap: "round",
                d: "M6.5 6h4M6.5 8.5h4M5 24h10M13.1 14.51l-.2 1.99"
              }
            ),
            /* @__PURE__ */ jsx("path", { d: "m19.68 9.41-.04-4.21M15.88 3l.05 8.61" }),
            /* @__PURE__ */ jsx(
              "path",
              {
                strokeLinecap: "round",
                d: "M24 7.25h6"
              }
            ),
            /* @__PURE__ */ jsx("path", { d: "M3.7 7.23v2.24c0 1.23.89 2.22 1.99 2.23l10.63.06c.22 0 .43-.07.61-.2l2.77-2.1h1.87c.09 0 .17-.02.24-.06l1.84-1.02s.05-.05.05-.09V6.18s-.02-.07-.05-.09l-1.84-1.02a.502.502 0 0 0-.24-.06H19.7l-2.73-2.03a.984.984 0 0 0-.6-.2H5.7c-1.1 0-2 1-2 2.23v2.23-.01Z" })
          ]
        }
      ),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "drill_svg__a", children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        }
      ) }) })
    ]
  }
));
var Drill_default = SvgDrill;

export {
  Drill_default
};
