"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Excavator.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgExcavator = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs(
        "g",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          clipPath: "url(#excavator_svg__a)",
          children: [
            /* @__PURE__ */ jsx("path", { d: "M9.5 16H4v7h14v-4.5M9.5 16l1.938-5H18v7.5M9.5 16H13l5 2.5M3.538 23h15.923s2 .5 2 3-2 3-2 3H3.538s-2.038-.5-2-3c.039-2.5 2-3 2-3ZM18 26h-1M12 26h-1M6 26H5" }),
            /* @__PURE__ */ jsx("path", { d: "M18 18.742 29.25 7.49s1.061-1.768-.706-3.536c-1.768-1.767-3.536-.707-3.536-.707L17.5 11M29.5 18.5H26m3.5 0V7m0 11.5v4s-1.215 1-2 1c-.785 0-4.5-1-4.5-1v-4h3m0 0L25 12" })
          ]
        }
      ),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "excavator_svg__a", children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        }
      ) }) })
    ]
  }
));
var Excavator_default = SvgExcavator;

export {
  Excavator_default
};
