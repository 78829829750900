"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/ForestMachine.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgForestMachine = withPhosphorWrapper(
  (props) => /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "1em",
      height: "1em",
      fill: "none",
      viewBox: "0 0 32 32",
      ...props,
      children: [
        /* @__PURE__ */ jsxs("g", { clipPath: "url(#forest_machine_svg__a)", children: [
          /* @__PURE__ */ jsx(
            "path",
            {
              stroke: "currentColor",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 1.5,
              d: "M31 19h-8v-5h5.323a1 1 0 0 1 .928.629L31 19ZM3 22h3.463M22.5 22H6.462m0 0V11M16 22V11M24.5 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM9.5 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM21.5 28h-9"
            }
          ),
          /* @__PURE__ */ jsx(
            "path",
            {
              stroke: "currentColor",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 1.5,
              d: "M6.5 28H4a1 1 0 0 1-1-1v-5M23 25.402V19h8v8a1 1 0 0 1-1 1h-2.5M23 21V10.5L15.5 2 12 4"
            }
          ),
          /* @__PURE__ */ jsx(
            "path",
            {
              stroke: "currentColor",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              d: "M11 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
            }
          ),
          /* @__PURE__ */ jsx(
            "path",
            {
              fill: "currentColor",
              fillRule: "evenodd",
              d: "M19.062 11.264a.753.753 0 0 0-.142-.014H2.08a.75.75 0 0 0-.148.015l.148.735-.148-.735H1.93l-.003.001-.008.002-.02.004a1.818 1.818 0 0 0-.238.072 2.38 2.38 0 0 0-.529.273c-.408.281-.846.79-.88 1.601-.036.856.431 1.39.859 1.673a2.445 2.445 0 0 0 .799.34l.02.004.008.001h.003l.002.001.138-.737-.137.737c.045.009.091.013.137.013H18.92a.753.753 0 0 0 .142-.014l-.142-.736.142.736h.006l.008-.002.02-.005a1.859 1.859 0 0 0 .242-.07c.144-.052.34-.136.541-.272.418-.282.87-.804.87-1.637s-.452-1.355-.87-1.637a2.408 2.408 0 0 0-.783-.342l-.02-.005-.008-.001h-.003l-.002-.001-.143.736.142-.736Zm-.241 1.486a.906.906 0 0 1 .218.106c.122.082.21.185.21.394a.427.427 0 0 1-.21.394.906.906 0 0 1-.218.106H2.175a.945.945 0 0 1-.236-.11c-.124-.082-.196-.172-.188-.358a.5.5 0 0 1 .232-.43.878.878 0 0 1 .193-.099l.009-.003h16.636Zm.242 3.514a.755.755 0 0 0-.142-.014H2.082a.75.75 0 0 0-.148.015l.148.735-.148-.735h-.003l-.003.001-.008.002-.02.004a1.811 1.811 0 0 0-.238.073 2.38 2.38 0 0 0-.529.272c-.408.281-.846.79-.88 1.601-.036.856.432 1.39.859 1.673a2.445 2.445 0 0 0 .799.34l.02.004.008.001h.004l.001.001s.002 0 .138-.737l-.136.737c.045.009.09.013.136.013h16.839a.755.755 0 0 0 .142-.014l-.142-.736.142.736h.006l.008-.002.02-.005a1.856 1.856 0 0 0 .243-.07c.143-.051.34-.136.54-.272.418-.282.87-.804.87-1.637s-.452-1.355-.87-1.637a2.408 2.408 0 0 0-.782-.342l-.02-.005-.009-.001h-.003l-.002-.001-.143.736.142-.736Zm-.24 1.486a.904.904 0 0 1 .218.106c.121.082.209.185.209.394a.427.427 0 0 1-.21.394.902.902 0 0 1-.2.1l-.018.006H2.176a.944.944 0 0 1-.236-.11c-.124-.082-.196-.172-.188-.358a.5.5 0 0 1 .233-.43.878.878 0 0 1 .193-.099l.008-.003h16.636Z",
              clipRule: "evenodd"
            }
          ),
          /* @__PURE__ */ jsx(
            "path",
            {
              stroke: "currentColor",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 1.5,
              d: "M14 7a3 3 0 1 0-6 0"
            }
          )
        ] }),
        /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "forest_machine_svg__a", children: /* @__PURE__ */ jsx(
          "path",
          {
            fill: "#fff",
            d: "M0 0h32v32H0z"
          }
        ) }) })
      ]
    }
  )
);
var ForestMachine_default = SvgForestMachine;

export {
  ForestMachine_default
};
