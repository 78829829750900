"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Kitchen.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgKitchen = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs(
        "g",
        {
          stroke: "currentColor",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          clipPath: "url(#kitchen_svg__a)",
          children: [
            /* @__PURE__ */ jsx("path", { d: "M3 16.84h25.57c.24 0 .43.21.43.47v11.18c0 .26-.19.47-.43.47h-16.9" }),
            /* @__PURE__ */ jsx("path", { d: "M3.5 3h7.67c.28 0 .5.22.5.5v25.46H3.5c-.28 0-.5-.22-.5-.5V3.5c0-.28.22-.5.5-.5Z" }),
            /* @__PURE__ */ jsx(
              "path",
              {
                strokeLinecap: "round",
                d: "M26.68 16.84v-3.73s0-1.86-1.82-1.86-1.82 1.86-1.82 1.86v.47"
              }
            ),
            /* @__PURE__ */ jsx("path", { d: "M20.33 16.84 20.31 29" }),
            /* @__PURE__ */ jsx(
              "path",
              {
                strokeLinecap: "round",
                d: "M7.96 14.05h.8M7.96 19.61h.8M15.66 19.61h.8M23.26 19.61h.8"
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "kitchen_svg__a", children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        }
      ) }) })
    ]
  }
));
var Kitchen_default = SvgKitchen;

export {
  Kitchen_default
};
