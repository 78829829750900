"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Garage.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgGarage = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsxs(
        "g",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          clipPath: "url(#garage_svg__a)",
          children: [
            /* @__PURE__ */ jsx("path", { d: "M27 27V11.087a1 1 0 0 0-.512-.873L16.483 4.62a1 1 0 0 0-.97-.003l-9.995 5.497a1 1 0 0 0-.518.876v16.01M1.999 27h28M10 23.5h12M10 20h12M10 16.5h12" }),
            /* @__PURE__ */ jsx("path", { d: "M22 27V13H10v14" })
          ]
        }
      ),
      /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "garage_svg__a", children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        }
      ) }) })
    ]
  }
));
var Garage_default = SvgGarage;

export {
  Garage_default
};
